import React  from 'react';

import { Chip, Grid } from "@material-ui/core";
import { styled } from '@material-ui/core/styles';

// ui reflection of file view
function ChipLabel({ item }) {
  const { label, color, background } = item;

  return (
    <Grid>
      <StyledChip
      color={color}
      background={background}
        label={label}
      />
    </Grid>
  );
}

const StyledChip = styled(Chip)(({ color, background }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: '5px',
  height: "18px",
  gap: '10px',
  borderRadius: '5px',
  background: background || '#DFE1E5',
  '& .MuiChip-label': {
    padding: '2px 4px',
    color: color || '#505358',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '18px',
  },
}));

export default ChipLabel;
