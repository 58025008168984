export const statusMapping = {
  Started: {
    label: 'Generating...',
    background: 'blue',
    color: 'white',
  },
  Completed: {
    label: 'Completed',
    background: 'green',
    color: 'white',
  },
  Ignored: {
    label: 'Ignored',
    background: 'red',
    color: 'white',
  },
};

export const previewReportHeaders = {
  'User Report': [
    'National Health ID',
    'Email',
    'Full Name',
    'Gender',
    'Date of Birth',
    'State',
    'Questionnaire last completed on',
    'Outcome',
    'Clearance Status',
    'Employee Tiers',
    'User Segment',
    'Employee Id',
    'User Segments',
    'Division Name',
    'PPR',
    'EmployeeId',
    'Station Code',
    'Dept Name',
    'EmployeeId',
    'EmployerId',
    'Birth Place',
    'QuestFacilityId',
    'EmployerName',
  ],
  'Scan Report': [
    'National Health ID',
    'Email',
    'Full Name',
    'Phone Number',
    'Checked On',
    'Checked By',
    'Outcome',
    'Overridden Outcome',
    'Overridden Reason',
    'Overridden On',
    'Overridden By',
    'Questionnaire Expired',
    'Symptomatic',
    'Division Name',
    'PPR',
    'EmployeeId',
    'Station Code',
  ],
  'Assessment Report': [
    'National Health ID',
    'Email',
    'Full Name',
    'Gender',
    'Date of Birth',
    'State',
    'Questionnaire Completed On',
    'Outcome',
    'Clearance Status',
  ],
  'LabTest Report': ['National Health ID', 'Email', 'Full Name', 'Gender', 'Date of Birth', 'Result', 'Resulted Date'],
  'CRO Report': ['First Name', 'Last Name', 'Type', 'Gender', 'Date', 'Result'],
  'Active User Report': [
    'Tenant ID',
    'User Role',
    'National Health ID',
    'First Name',
    'Last Name',
    'User Creation Date',
    'Last Login Date',
  ],
};

export const reportTypes = {
  ASSESSMENT_REPORT: {
    id: 'ASSESSMENT_REPORT',
    title: 'Assessment Report',
    labels: {
      startLabel: 'Symptom Checker Date Start',
      endLabel: 'Symptom Checker Date End',
    },
  },
  SCAN_REPORT: {
    id: 'SCAN_REPORT',
    title: 'Scan Report',
    labels: {
      startLabel: 'Scan Date Start',
      endLabel: 'Scan Date End',
    }
  },
  USER_REPORT: {
    id: 'USER_REPORT',
    title: 'User Report',
    labels: {
      startLabel: 'Create Date Start',
      endLabel: 'Create Date End',
    }
  },
  CRO_REPORT: {
    id: 'CRO_REPORT',
    title: 'CRO Report',
    labels: {
      startLabel: 'Completion Date Start',
      endLabel: 'Completion Date End',
    }
  },
  LABTEST_REPORT: {
    id: 'LABTEST_REPORT',
    title: 'LabTest Report',
    labels: {
      startLabel: 'Specimen Collection Date Start',
      endLabel: 'Specimen Collection Date End',
    }
  },
  ACTIVE_USER_REPORT: {
    id: 'ACTIVE_USER_REPORT',
    title: 'Active User Report',
    labels: {
      startLabel: 'Create Date Start',
      endLabel: 'Create Date End',
    }
  },
  LOGIN_REPORT: {
    id: 'LOGIN_REPORT',
    title: 'Log-in Report',
    labels: {
      startLabel: 'Create Date Start',
      endLabel: 'Create Date End',
    }
  },
  ACTIVITY_REPORT: {
    id: 'ACTIVITY_REPORT',
    title: 'Activity Report',
    labels: {
      startLabel: 'Activity Date Start',
      endLabel: 'Activity Date End',
    }
  },
  DEMOGRAPHICS_REPORT: {
    id: 'DEMOGRAPHICS_REPORT',
    title: 'Employee Demographics Report',
    labels: {
      startLabel: 'Activity Date Start',
      endLabel: 'Activity Date End',
    }
  },
};
